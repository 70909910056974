import Vue from 'vue';
import { defineStore } from 'pinia'
import configurationService from '@/services/configuration.service';

export const useConfigStore = defineStore('config', {
    state: () => ({

    }),

    actions: {
        getReplaySelection() {
            const configProps = Vue.prototype.$session.get("configProperties");
            let columnIndex = configProps.columnConfig.findIndex(column => column.name === "replay-filter");
            if (columnIndex !== -1) {
                let column = configProps.columnConfig[columnIndex];

                return {
                    drivers: column.operators?.length > 0 ? column.operators.split(',') : [],
                    vehicles: column.assets?.length > 0 ? column.assets.split(',') : []
                }
            }
            return {
                drivers: [],
                vehicles: []
            }
        },
        saveReplaySelection(selection) {
            if (selection !== null && selection !== undefined) {
                const configProps = Vue.prototype.$session.get("configProperties");
                let columnIndex = configProps.columnConfig.findIndex(column => column.name === "replay-filter");
                let currentColumn = {
                    name: "replay-filter",
                    assets: selection.vehicles.join(','),
                    operators: selection.drivers.join(',')
                };
                if (columnIndex !== -1) {
                    configProps.columnConfig[columnIndex] = currentColumn;
                } else {
                    configProps.columnConfig.push(currentColumn);
                };
                let model = {
                    overrideProperties: {
                        columnConfig: configProps.columnConfig,
                    },
                };
                configurationService.updateUserConfigProperty(model);
                Vue.prototype.$session.set("configProperties", configProps);
            };
        }
    }
})