import axios from "axios";
import axiosRetry from 'axios-retry';
import Vue from 'vue';
import { EventBus } from './eventbus.js';
import common from "@/../constant/common"
import router from "@/router";
import toast from '@/shared/toast';
import loginService from "@/service/login.service";

Vue.prototype.$eventBus = EventBus;
var ignoreHttpErrorUrl = ["trips", "driverscore", "DriverScoreByPeriod"];
var ignoreLoaderUrl = ["notActioned", "driverscore", "getWorkingHours","GeoCode", "LPD", 'lastDevicePosition?activeAfterTime=48&activeAfterTimeInUnit=hour', 'lastDevicePosition?activeBeforeTime=48&activeBeforeTimeInUnit=hour',];
var apiRequestCount = {};
var numberOfAjaxCAllPending = 0;
var interceptorInitialized = false;
var showLoader = false;

// Configure axios-retry
axiosRetry(axios, {
    retries: 3, // Number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 1000; // Time in ms (increasing delay between each retry)
    },
    retryCondition: (error) => {
        // Retry on network errors or 5xx status codes
        return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status >= 500;
    },
});

const httpInterceptor = {

    initialize() {
        if (interceptorInitialized) {
            return;
        }
        interceptorInitialized = true
        axios.interceptors.request.use(
            configAPI => {
                configAPI.headers = {}
                numberOfAjaxCAllPending++;
                if (configAPI.url.indexOf("hereapi.com") !== -1) {
                    return configAPI;
                }
                const session = Vue.prototype.$session;
                let sessionHeaders = true;
                if (configAPI.params) {
                    sessionHeaders = configAPI.params.sessionHeaders;
                    if (configAPI.params.showLoader) {
                        showLoader = configAPI.params.showLoader
                    }else{
                        showLoader = false;
                    }
                }
                const configUrl = configAPI.url;
                const urls = configUrl.split("/");
                var intersections = urls.filter(e => ignoreLoaderUrl.indexOf(e) !== -1);

                if (intersections.length > 0 && showLoader == false) {
                    EventBus.$emit('httpRequestActive', false);
                } else {
                    EventBus.$emit('httpRequestActive', true);
                }
                if (session.has("tenantDetails") && session.get("tenantDetails") && sessionHeaders != false) {

                    const userData = session.get("tenantDetails");
                    configAPI.headers["x-user"] = userData.userId;
                    configAPI.headers["Ocp-Apim-Subscription-Key"] = userData.subscriptionKey;
                    configAPI.headers["x-tenant"] = userData.tenantId;
                }
                return configAPI;
            },

            error => {
                numberOfAjaxCAllPending--;
                EventBus.$emit('onError', error);
                EventBus.$emit('httpRequestActive', false);
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            function (response) {
                numberOfAjaxCAllPending--;
                if (numberOfAjaxCAllPending == 0) {
                    EventBus.$emit('httpRequestActive', false);
                }
                apiRequestCount[response.config.url] = 0;
                //const data = response.data;
                //data.status =  response.status;
                //data.statusText =  response.statusText;
                //data.headers =  response.headers;
                return response.data;
            },
            function (error) {
                numberOfAjaxCAllPending--;
                const configUrl = error && error.config ? error.config.url : "";
                let apiCount = apiRequestCount[configUrl] || 0;
                const params = error.config ? error.config.params : {};
                if (error && error.response && error.response.status === 401 && apiCount < 1) {
                    // Log user out and redirect to login screen if authorization expired
                    apiCount += 1;
                    apiRequestCount[error.response.config.url] = apiCount;
                    common.wait(5000);
                    return axios.request(error.config);
                } else if (error && error.response && error.response.status === 401 && apiCount == 1) {
                    loginService.logout().then(() => {
                        localStorage.clear();
                        router.push('/')
                        toast.error("You are not authorized for the requested Module. Please contact Administrator.");
                    })
                    return;
                }
                if (error && error.response && error.response.status === 403) {
                    // Log user out and redirect to login screen if authorization expired
                    loginService.logout().then(() => {
                        localStorage.clear();
                        router.push('/')
                        toast.error("Your login has expired. Please log in again.");
                    })
                    return;
                }
                EventBus.$emit('httpRequestActive', false);
                const urls = configUrl.split("/");
                const urlWithParams = urls[urls.length - 1];
                const url = urlWithParams.split("?")[0];
                let showDefaultError = true;
                if (params && (params.showDefaultError == false || params.showDefaultError == 'false')) {
                    showDefaultError = false;
                }
                if (url && ignoreHttpErrorUrl.includes(urls) && showDefaultError == true) {
                    EventBus.$emit('onError', error);
                }
                return Promise.reject(error);
            }
        );

    }
}

export default httpInterceptor;
