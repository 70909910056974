<template>
    <td :class="className">
        <div class="currentStatus img">
            <span class="status-circle">
                <img v-if="dataItem.vehicleStatus" :src="statusImage" width="25" height="25" />
            </span>
            <span class="vehiclestatus">
                {{dataItem.vehicleStatus}}
            </span>
        </div>
    </td>
</template>
<script>
    import { StylesMixin } from "ctrack-shared-misc";
    export default {
        name: "StatusCell",
        mixins: [StylesMixin],
        data() {
            return {
                statusImage: this.getStatusImage(this.dataItem.vehicleStatusValue)
            };
        },
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        }
    };
</script>
<style scoped>
    .currentStatus img {
        margin-right: 25px;
    }
</style>