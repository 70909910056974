<template>
    <div style="padding-left: 16px;">
        <v-tooltip v-if="displayLoader" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs"
                          v-on="on"
                          :color="statusColour"
                          :size="32">
                    <v-icon small v-if="status == 'LOADED'" dark>cloud_download</v-icon>
                    <v-progress-circular :size="32" v-if="status == 'LOADING'" color="secondary"
                                         indeterminate>
                        <v-icon small dark>cloud_download</v-icon>
                    </v-progress-circular>
                    <v-icon v-if="status == 'FAILED'" small dark>cloud_download</v-icon>
                </v-avatar>
            </template>
            <span>{{toolTipDisplay}}</span>
        </v-tooltip>
    </div>
</template>
<script>
    export default {
        props: {
            status: {
                type: String,
                default() {
                    return 'LOADED';
                },
            },
            showIfLoaded: {
                type: Boolean,
                default() {
                    return false;
                },
            },            
        },
        computed: {
            displayLoader() {
                switch (this.status) {
                    case 'LOADING':
                    case 'FAILED':
                        return true
                        break;
                    case 'LOADED':
                        return this.showIfLoaded;
                        break;              
                    default:
                        return false;
                        break;
                }; 
            },
            statusColour() {
                switch (this.status) {
                    case 'LOADING':
                        return 'primary';
                        break;
                    case 'FAILED':
                        return 'error';
                        break;
                    case 'LOADED':
                        return 'success';
                        break;
                    default:
                        return 'warning';
                        break;
                }; 
            },
            toolTipDisplay() {
                switch (this.status) {
                    case 'LOADING':
                        return this.$t('loading');
                    case 'FAILED':
                        return this.$t('sideDrawer.fail') + 'Retrying';
                        break;
                    case 'LOADED':
                        return "Information Loaded";
                        break;
                    default:
                        return this.$t('loading');
                        break;
                }; 
            },
        },
    }
</script>
<style scoped>
    .green {
        color: #39bfad;
    }
    
</style>