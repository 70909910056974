import Vue from 'vue';
import { defineStore } from 'pinia';
import ruleService from "@/service/rule.service";
export const useAlertPoliciesStore = defineStore('alertPolicies', {
    state: () => ({
        policies: [],
    }),
    //$alertPoliciesStore 
    actions: {
        async getAlertPolicies(allowedClaims = [], token = null) {
            const params = {
                pageSize : 200
            };
            if (token) { params.nextPageToken = token };
            await ruleService.getAllRulePolicy(params, "").then(policyList => {
                policyList = policyList.data || [];
                if (allowedClaims.length > 0) {
                    [...new Set(policyList.map(i => i.triggerName))].forEach(triggerName => {
                        if (!allowedClaims.includes('FleetAlerts.' + triggerName)) {
                            policyList = policyList.filter(i => i.triggerName != triggerName);
                        };
                    })
                };
                this.policies = policyList;               
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Alert Policies');
                this.policies = [];
            });
        },
        async getAlertPoliciesAssignments(allowedClaims = [], token = null) {
            const params = {
                pageSize: 200
            };
            if (token) { params.nextPageToken = token };
            await ruleService.getRulePoliciesAssignments(params, "").then(policyList => {
                policyList = policyList.data || [];
                if (allowedClaims.length > 0) {
                    [...new Set(policyList.map(i => i.triggerName))].forEach(triggerName => {
                        if (!allowedClaims.includes('FleetAlerts.' + triggerName)) {
                            policyList = policyList.filter(i => i.triggerName != triggerName);
                        };
                    })
                };
                this.policies = policyList;
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Alert Policies');
                this.policies = [];
            });
        },
        clearAlertPolicies() {
            this.policies = [];
        },
        getAlertPoliciesData() {
            return this.policies;
        },
        updateAlertPolicy(policy) {
            this.policies = [...this.policies.filter(i => i.id != policy.id), policy];
        },
        removeAlertPolicy(id) {
            this.policies = this.policies.filter(i => i.id != id);
        },

    }
})