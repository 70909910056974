import vehicleService from "@/service/vehicles.service";
import filters from '@/shared/filters';
import { useCommonStore } from '@/stores/common-store';
import { useGroupsFilterStore } from '@/stores/groups-store';
import { useLocationStore } from '@/stores/location-store';
import { useInsightsStore } from '@/stores/insights-store';
import _ from 'lodash';
import { CommonService, ConfigurationService } from "../service";
import LocationService from "../service/locations.service";
import { LiveMapService } from "../services";

import Vue from 'vue';
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const loadedPercentages = {
    tollgate: 10,
    locations: 20,
    drivers: 20,
    assetList : 4,
    vehicles: 45,
};
const state = () => ({
    vehicles: [],
    drivers: [],
    locations: [],
    tollgates: [],
    lazyLoading: 0,
    tenantId: "",
})

const getters = {}

const actions = {
    clearStore({ commit }) {
        commit('clearStore');
    },
    tenantValidation({ commit, state }) {
        let tenantId = Vue.prototype.$session.get('tenantDetails')
            ? Vue.prototype.$session.get('tenantDetails').tenantId
            : ''
        console.log("Comparing Tenants", state.tenantId, tenantId)
        if (state.tenantId != tenantId) {
            console.log("Clearing Store");
            commit('clearStore');
        };
        commit('setTenant', tenantId);
    },
    //#region Perspectives
    async getAll({ commit, state, dispatch }) {
        dispatch('tenantValidation');
        commit('setLazyLoadingState', 1);
        const groupFilterStore = useGroupsFilterStore();     
        const insightsStore = useInsightsStore();
        const payload = {
            vehicles: [],
            drivers: [],
            locations: [],
        };
        const locationStore = useLocationStore();
        var locationsLoaded = false; 
        var driversLoaded = false;
        var tollgatesLoaded = false;
        //Tollgate
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
                if (state.lazyLoading != 0) {
                    commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.tollgate);
                };
                tollgatesLoaded = true;
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'getTollgates', err);
                tollgatesLoaded = true;
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
            commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.tollgate);
            tollgatesLoaded = true;
        };        
        //Locations
        if (locationStore.locations.length == 0) {     
            payload.locations = [...groupFilterStore.locations];
            if (payload.locations.length > 0) {
                //Before we do anything, lets check the state.              
                if (payload.locations.filter(locationId => !state.locations.includes(locationId)).length == 0) {
                    //Every location we are looking for is in our stated
                    commit('setLocations', state.locations.filter(location => payload.locations.includes(location.id)));
                    if (state.lazyLoading != 0) {
                        commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.locations);
                    };
                    locationsLoaded = true;
                } else {
                    LiveMapService.getAll(payload).then(locationResponse => {
                        commit('setLocations', locationResponse.locations);
                        if (state.lazyLoading != 0) {
                            commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.locations);
                        };
                        locationsLoaded = true;
                    }).catch(err => {
                        insightsStore.trackError('LiveMap', 'getAll - Locations', err);
                        locationsLoaded = true;
                    });
                };
            } else {
                commit('setLocations', []);
                commit('setLazyLoadingState',
                    (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                    + loadedPercentages.locations);
                locationsLoaded = true;
            };
        } else {          
            commit('setLocations', locationStore.locations);
            commit('setLazyLoadingState',
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + loadedPercentages.locations);
            locationsLoaded = true;
        }; 
        //We only need to determine the list is the groupFilter returns all
        let assetList = [...groupFilterStore.vehicles];
        if (assetList.length > 0) {
            if (assetList.map(i => i.toLowerCase()).includes("all")) {
                assetList = await vehicleService.getAssetLight({ ids: groupFilterStore.vehicles }).then((lightAssets) => {
                    return lightAssets.map(i => i.id);
                }).catch(err => {
                    insightsStore.trackError('LiveMap', 'getAssetLight', err);
                    return [];
                });
            };
            commit('setLazyLoadingState',
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + (locationsLoaded ? loadedPercentages.locations : 0)
                + loadedPercentages.assetList);
            //Then start the cycle
            payload.locations = [];
            payload.drivers = [...groupFilterStore.drivers];
            let totalAssets = assetList.length;
            let totalLoaded = 0;
            let chunks = [];
            chunks.push(assetList.splice(0, 100));
            while (assetList.length > 0) {
                chunks.push(assetList.splice(0, 200));
            };
            for await (var batch of chunks) {
                payload.vehicles = batch;
                await LiveMapService.getAll(payload).then((result) => {
                    if (!driversLoaded) {
                        driversLoaded = true;
                        let drivers = result.drivers.length > 0
                            ? result.drivers.map(operator => {
                                return {
                                    ...operator,
                                    profileImage: filters.methods.$getBlobStorageImage(operator.profileImage),
                                }
                            })
                            : [];
                        commit('setDrivers', drivers);
                        commit('setLazyLoadingState',
                            (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                            + (locationsLoaded ? loadedPercentages.locations : 0)
                            + loadedPercentages.assetList
                            + loadedPercentages.drivers
                        );
                        payload.drivers = [];
                    };
                    let vehicles = result.vehicles.map(asset => {
                        return {
                            ...asset,
                            profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                            vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                            driver: asset?.driver?.driverId
                                ? {
                                    ...asset.driver,
                                    profileImage: asset.driver ? filters.methods.$getBlobStorageImage(asset.driver.profileImage) : null
                                }
                                : state.drivers.find(i => i.id == asset.driverId) || asset.driver
                        }
                    });
                    vehicles.forEach(asset => {
                        commit('addVehicle', asset);
                    });
                    totalLoaded = totalLoaded + batch.length;
                    commit('setLazyLoadingState',
                        (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                        + (locationsLoaded ? loadedPercentages.locations : 0)
                        + loadedPercentages.assetList
                        + loadedPercentages.drivers
                        + Math.round((totalLoaded / totalAssets) * loadedPercentages.vehicles)
                    );
                }).catch(err => {
                    insightsStore.trackError('LiveMap', 'getAll - Batch', err);
                    totalLoaded = totalLoaded + batch.length;
                    commit('setLazyLoadingState',
                        (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                        + (locationsLoaded ? loadedPercentages.locations : 0)
                        + loadedPercentages.assetList
                        + loadedPercentages.drivers
                        + Math.round((totalLoaded / totalAssets) * loadedPercentages.vehicles)
                    );
                });
            };    
        } else {
            //Asset List is 0
            commit('setVehicles', []);
            insightsStore.trackError('LiveMap', 'No Assets');
        };
        commit('setLazyLoadingState', 0);
    },
    async getAssetSelectionPerspective({ commit, state, dispatch }, { filteredAssets = [] }) {
        dispatch('tenantValidation');
        commit('setLazyLoadingState', 1);
        var locationsLoaded = false; 
        var driversLoaded = false;
        var tollgatesLoaded = false;
        const insightsStore = useInsightsStore();
        const groupFilterStore = useGroupsFilterStore();    
        const locationStore = useLocationStore();
        const payload = {
            vehicles: [],
            drivers: [],
            locations: [],
        };
        let loadedIds = state.vehicles.filter(i => i.preloaded != true).map(i => i.id);
        //Tollgates
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
                if (state.lazyLoading != 0) {
                    commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.tollgate);
                }
                tollgatesLoaded = true;
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'setTollgates', err);
                tollgatesLoaded = true;
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
            commit('setLazyLoadingState', loadedPercentages.tollgate);
            tollgatesLoaded = true;
        };     
        //Locations
        if (locationStore.locations.length == 0) {
            payload.locations = [...groupFilterStore.locations];
            if (payload.locations.length > 0) {
                //Before we do anything, lets check the state.
                if (payload.locations.filter(locationId => !state.locations.includes(locationId)).length == 0) {
                    //Every location we are looking for is in our stated
                    commit('setLocations', state.locations.filter(location => payload.locations.includes(location.id)));
                    if (state.lazyLoading != 0) {
                        commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.locations);
                    };
                    locationsLoaded = true;
                } else {
                    LiveMapService.getAll(payload).then(locationResponse => {
                        commit('setLocations', locationResponse.locations);
                        if (state.lazyLoading != 0) {
                            commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.locations);
                        };
                        locationsLoaded = true;
                    }).catch(err => {
                        insightsStore.trackError('LiveMap', 'getAll - Locations', err);
                        locationsLoaded = true;
                    });
                };
            } else {
                commit('setLocations', []);
                commit('setLazyLoadingState',
                    (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                    + loadedPercentages.locations);
                locationsLoaded = true;
            };    
        } else {
            commit('setLocations', locationStore.locations);
            commit('setLazyLoadingState',
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + loadedPercentages.locations);
            locationsLoaded = true;
        };      
        //Get All Light
        if (groupFilterStore.vehicles.length > 0) {
            vehicleService.getAssetLight({ ids: groupFilterStore.vehicles }).then(async (lightAssets) => {
                if (lightAssets.length > 0) {
                    commit('setLazyLoadingState',
                        (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                        + (locationsLoaded ? loadedPercentages.locations : 0)
                        + loadedPercentages.assetList
                    );
                    //Set starting point for assets while we load
                    let tempAssets = lightAssets.map(i => {
                        if (loadedIds.includes(i.id)) { //Maintain values where already in state
                            return state.vehicles.find(l => l.id == i.id);
                        } else { // Load rest as preload
                            return {
                                ...i,
                                preloaded: true,
                                //the below is added temporarily and must be removed after updated endpoint
                                lastPosition: {
                                    bpStatusAttribute: 0,
                                    lastKnownEventTime: new Date(),
                                    status: 0,
                                },
                            };
                        };
                    });
                    commit('setVehicles', tempAssets);
                    //Then start the cycle
                    payload.locations = [];
                    payload.drivers = [...groupFilterStore.drivers];
                    let assetList = lightAssets
                        .map(i => i.id)
                        .filter(i => filteredAssets.length != 0
                            ? filteredAssets.includes(i)
                            : true);
                    let totalAssets = assetList.length;
                    let totalLoaded = 0;
                    let chunks = [];
                    chunks.push(assetList.splice(0, 100));
                    while (assetList.length > 0) {
                        chunks.push(assetList.splice(0, 200));
                    };
                    ////
                    for await (var batch of chunks) {
                        payload.vehicles = batch;
                        await LiveMapService.getAll(payload).then((liveMap) => {
                            //Adjust Total based on qty called and qty returned
                            if (!driversLoaded) {
                                //Apply Drivers
                                let drivers = liveMap.drivers.length > 0
                                    ? liveMap.drivers.map(operator => {
                                        return {
                                            ...operator,
                                            profileImage: filters.methods.$getBlobStorageImage(operator.profileImage),
                                        }
                                    })
                                    : [];
                                commit('setDrivers', drivers);
                                driversLoaded = true;
                                commit('setLazyLoadingState',
                                    (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                                    + (locationsLoaded ? loadedPercentages.locations : 0)
                                    + loadedPercentages.assetList
                                    + loadedPercentages.drivers
                                );
                            };
                            //#region Implimented as state was never checked, 
                            let parsedVehicles = liveMap.vehicles.map(asset => {
                                return {
                                    ...asset,
                                    profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                                    vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                                    driver: asset?.driver?.driverId
                                        ? {
                                            ...asset.driver,
                                            profileImage: asset.driver ? filters.methods.$getBlobStorageImage(asset.driver.profileImage) : null
                                        }
                                        : state.drivers.find(i => i.id == asset.driverId) || asset.driver
                                }
                            });
                            parsedVehicles.forEach(asset => {
                                commit('addVehicle', asset);
                            });                       
                            totalLoaded = totalLoaded + batch.length;
                            commit('setLazyLoadingState',
                                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                                + (locationsLoaded ? loadedPercentages.locations : 0)
                                + loadedPercentages.drivers
                                + loadedPercentages.assetList
                                + Math.round((totalLoaded / totalAssets) * loadedPercentages.vehicles)
                            );
                        }).catch(err => {
                            insightsStore.trackError('LiveMap', 'getAll - Batch', err);
                            totalLoaded = totalLoaded + batch.length;
                            commit('setLazyLoadingState',
                                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                                + (locationsLoaded ? loadedPercentages.locations : 0)
                                + loadedPercentages.drivers
                                + loadedPercentages.assetList
                                + Math.round((totalLoaded / totalAssets) * loadedPercentages.vehicles)
                            );
                        }); 
                    };
                    commit('setLazyLoadingState', 0);
                } else {
                    //Light Assets = 0
                    commit('setVehicles', []);
                    insightsStore.trackError('LiveMap', 'No Assets');
                    commit('setLazyLoadingState', 0);
                };              
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'getAssetLight', err);
                commit('setLazyLoadingState', 0);
            }); 
        } else {
            //0 Assets
            commit('setVehicles', []);
            insightsStore.trackError('LiveMap', 'No Assets');
            commit('setLazyLoadingState', 0);
        };
            
    },
    async getOperatorSelectionPerspective({ commit, state, dispatch }, { filteredOperators = [] }) {
        dispatch('tenantValidation');
        commit('setLazyLoadingState', 1);
        const insightsStore = useInsightsStore();
        const groupFilterStore = useGroupsFilterStore();
        const locationStore = useLocationStore();
        var locationsLoaded = false; 
        var tollgatesLoaded = false;
        const payload = {
            vehicles: [],
            drivers: [],
            locations: [],
        };
        let loadedIds = state.vehicles.filter(i => i.preloaded != true).map(i => i.id);
        //Tollgates
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
                if (state.lazyLoading != 0) {
                    commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.tollgate);
                };
                tollgatesLoaded = true;
            }).catch(err => {
                insightsStore.trackError('LiveMap', 'setTollgates', err);
                tollgatesLoaded = true;
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
            commit('setLazyLoadingState', loadedPercentages.tollgate);
            tollgatesLoaded = true;
        };       
        //Locations
        if (locationStore.locations.length == 0) {
            payload.locations = [...groupFilterStore.locations];
            if (payload.locations.length > 0) {
                //Before we do anything, lets check the state.
                if (payload.locations.filter(locationId => !state.locations.includes(locationId)).length == 0) {
                    //Every location we are looking for is in our stated
                    commit('setLocations', state.locations.filter(location => payload.locations.includes(location.id)));
                    if (state.lazyLoading != 0) {
                        commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.locations);
                    }
                    locationsLoaded = true;
                } else {
                    LiveMapService.getAll(payload).then(locationResponse => {
                        commit('setLocations', locationResponse.locations);
                        //locationStore.replaceLocationState(locationResponse.locations);
                        if (state.lazyLoading != 0) {
                            commit('setLazyLoadingState', state.lazyLoading + loadedPercentages.locations);
                        };
                        locationsLoaded = true;
                    }).catch(err => {
                        insightsStore.trackError('LiveMap', 'getAll - Locations', err);
                        locationsLoaded = true;
                    });
                };
            } else {
                commit('setLocations', []);
                commit('setLazyLoadingState',
                    (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                    + loadedPercentages.locations);
                locationsLoaded = true;
            };  
        } else {
            commit('setLocations', locationStore.locations);
            commit('setLazyLoadingState',
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + loadedPercentages.locations
            );
            locationsLoaded = true;
        };    
        //Get All Light
        payload.locations = [];
        payload.drivers = [...groupFilterStore.drivers];
        Promise.all([
            vehicleService.getAssetLight({ ids: groupFilterStore.vehicles }),
            LiveMapService.getAll(payload),
        ]).then(async ([
            lightAssets,
            liveMapResponse,
        ]) => {
            commit('setLazyLoadingState', 
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + (locationsLoaded ? loadedPercentages.locations : 0)
                + loadedPercentages.assetList
            );
            let lightOperators = liveMapResponse.drivers;
            //Set Drivers
            let drivers = lightOperators.length > 0
                ? lightOperators.map(operator => {
                    return {
                        ...operator,
                        profileImage: filters.methods.$getBlobStorageImage(operator.profileImage),
                    }
                })
                : []
            commit('setDrivers', drivers);
            payload.drivers = [];
            commit('setLazyLoadingState',
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + (locationsLoaded ? loadedPercentages.locations : 0)
                + loadedPercentages.drivers
            );
            //Set starting point for assets while we load
            let tempAssets = lightAssets.map(i => {
                if (loadedIds.includes(i.id)) { //Maintain values where already in state
                    return state.vehicles.find(l => l.id == i.id);
                } else { // Load rest as preload
                    return {
                        ...i,
                        preloaded: true,
                        //the below is added temporarily and must be removed after updated endpoint
                        lastPosition: {
                            bpStatusAttribute: 0,
                            lastKnownEventTime: new Date(),
                            status: 0,
                        },
                    };
                };
            });
            commit('setVehicles', tempAssets);
            //Now run Filter         
            commit('setLazyLoadingState', 
                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                + (locationsLoaded ? loadedPercentages.locations : 0)   
                + loadedPercentages.drivers
                + loadedPercentages.assetList
            );
            //If we have a filter - then load only the filter
            if (filteredOperators.length > 0) {
                let pendingAssets = lightOperators
                    .filter(i => filteredOperators.includes(i.driverId)) // Where in out filter
                    .map(i => i?.assignedVehicle?.vehicleId) //Return only vehicleId Field
                    .filter(i => i) // Return only where there is a value
                    .filter(i => !loadedIds.includes(i)) // Return where not already in state
                if (pendingAssets.length > 0) {
                    let totalAssets = pendingAssets.length;
                    let totalLoaded = 0;
                    let chunks = [];
                    let assetList = pendingAssets;
                    chunks.push(assetList.splice(0, 100));
                    while (assetList.length > 0) {
                        chunks.push(assetList.splice(0, 200));
                    };
                    for await (var batch of chunks) {
                        payload.vehicles = batch;
                        await LiveMapService.getAll(payload).then((liveMap) => {
                            //Adjust Total based on qty called and qty returned                          
                            let parsedVehicles = liveMap.vehicles.map(asset => {
                                return {
                                    ...asset,
                                    profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                                    vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                                    driver: state.drivers.find(i => i.id == asset.driverId) || {
                                        ...asset.driver,
                                        profileImage: asset.driver ? filters.methods.$getBlobStorageImage(asset.driver.profileImage) : null
                                    }
                                }
                            });
                            parsedVehicles.forEach(asset => {
                                commit('addVehicle', asset);
                            });
                            totalLoaded = totalLoaded + batch.length;
                            commit('setLazyLoadingState',
                                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                                + (locationsLoaded ? loadedPercentages.locations : 0)
                                + loadedPercentages.drivers
                                + loadedPercentages.assetList
                                + Math.round((totalLoaded / totalAssets) * loadedPercentages.vehicles)
                            );            
                        }).catch(err => {
                            insightsStore.trackError('LiveMap', 'getAll - Batch', err);
                            totalLoaded = totalLoaded + batch.length;
                            commit('setLazyLoadingState',
                                (tollgatesLoaded ? loadedPercentages.tollgate : 0)
                                + (locationsLoaded ? loadedPercentages.locations : 0)
                                + loadedPercentages.drivers
                                + loadedPercentages.assetList
                                + Math.round((totalLoaded / totalAssets) * loadedPercentages.vehicles)
                            );        
                        }); 
                    };   
                };
            };
            commit('setLazyLoadingState', 0);
        }).catch(err => {
            insightsStore.trackError('LiveMap', 'getAssetLight + Drivers', err);
            commit('setLazyLoadingState', 0);
        });
    },
    //#endregion

    //#region Individual Calls
    getLocations({ commit }) {
        const locationStore = useLocationStore();
        if (locationStore.getLocationsData().length == 0) {
            LocationService.searchByGroupIds().then(results => {
                locationStore.replaceLocationState(results.items);
                commit('setLocations', results.items);
            })
        } else {
            commit('setLocations', locationStore.getLocationsData());
        };
    },
    async getSelectedAsset({ commit, state }, { pendingAssets }) {
        let totalAssets = pendingAssets.length;
        let totalLoaded = 0;
        let chunks = [];
        commit('setLazyLoadingState', 1);
        chunks.push(pendingAssets.splice(0, 50));
        while (pendingAssets.length > 0) {
            chunks.push(pendingAssets.splice(0, 50));
        };
        for await (var batch of chunks) {
            const payload = {
                vehicles: batch,
                drivers: [],
                locations: [],
            };
            await LiveMapService.getAll(payload).then((result) => {
                let vehicles = result.vehicles.map(asset => {
                    return {
                        ...asset,
                        profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                        vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                        driver: state.drivers.find(i => i.id == asset.driverId) || asset.driver,
                    }
                });
                let stepCount = 0;
                vehicles.forEach(asset => {
                    stepCount = stepCount + 1;
                    commit('setLazyLoadingState', Math.round(((totalLoaded + stepCount) / totalAssets)*100));
                    commit('addVehicle', asset);
                });
                totalLoaded = totalLoaded + batch.length;
                commit('setLazyLoadingState', Math.round((totalLoaded  / totalAssets)*100));
            });
        };  
        commit('setLazyLoadingState', 0);
    },
    getAsset({ commit, state }, { changes, deviceId, driverId, vehicleId }) {
        let item = state.vehicles.filter(c => c.id == vehicleId).at(0);
        if (item != null && item.preloaded == true) {
            const payload = {
                vehicles: [vehicleId],
                drivers: [],
                locations: [],
            };
            return LiveMapService.getAll(payload).then((result) => {
                let vehicles = result.vehicles.map(asset => {
                    return {
                        ...asset,
                        profileImage: filters.methods.$getBlobStorageImage(asset.profileImage),
                        vehicleIcon: filters.methods.$getBlobStorageImage(asset.vehicleIcon),
                    }
                });
                commit('addVehicle', vehicles[0]);
                if (changes) {
                    commit('updateVehicle', {
                        changes, deviceId,
                        driverId, vehicleId
                    });
                };
                return vehicles[0];
            });
        } else {
            return null;
        };
    },
    //#endregion

    //#region SignalR
    processSignalRQue({ commit, state }, signalRQue) {
        //First filter out updates we dont care about
        signalRQue.forEach(q => {
            commit('updateVehicle', q);
        });
    },
    //#endregion
}

const mutations = {
    setTenant(state, tenantId) {
        state.tenantId = tenantId;
    },
    setVehicles(state, vehicles) {
        Object.assign(state.vehicles, vehicles);
    },
    setDrivers(state, drivers) {
        Object.assign(state.drivers, drivers);
    },
    setLocations(state, locations) {
        var data = locations.length != 0
            ? locations.map(i => {
                if (i.type === 'CIRCLE' || i.type === 'MARKER' || i.type === 'POINT') {
                    i.position = i.position
                        ? {
                            ...i.position,
                            lat: i.position.lat || i.position.latitude || 0,
                            lng: i.position.lng || i.position.longitude || 0,
                        }
                        : {
                            lat: 0,
                            lng: 0,
                        };
                    if (Math.abs(i.position.lat) > 90) {
                        i.position.lat = 0;
                    };
                    if (Math.abs(i.position.lng) > 90) {
                        i.position.lat = 0;
                    };
                };
                return i;
            })
            : locations
        if (data.length != 0) {
            state.locations = data
        } else {
            Object.assign(state.locations, data);
        };
        
    },
    setTollgates(state, tollgates) {
        state.tollgates = tollgates;
    },
    updateFilter(state, { sort }) {
        ConfigurationService.updateSortingConfig("livemap-filter", sort);
    },
    addVehicle(state, vehicle) {
        let index = state.vehicles.map(i => i.id).indexOf(vehicle.id);
        if (index != -1) {
            state.vehicles.splice(index, 1);
        };
        state.vehicles.push(vehicle);
    },
    updateVehicle(state, { changes, deviceId, driverId, vehicleId }) {
        let item = state.vehicles.filter(c => c.id == vehicleId).at(0);
        if (item == null) {
            return;
        };
        if (driverId) {
            item.driver = state.drivers.filter(c => c.id == driverId).at(0);
        };
        if (_.isNil(changes.address)) {
            changes.address = {};
        };
        if (_.isNil(changes.edgeCompute)) {
            item.edgeCompute = {};
        };

        if (changes.edgeCompute) {
            item.edgeCompute = changes.edgeCompute;
        }

        item.heading = changes.heading;
        item.latitude = changes.latitude;
        item.longitude = changes.longitude;
        item.locationDetail = changes.locationDetail;
        item.speed = changes.speed;
        item.vehicleStatus = changes.statusText;
        item.odometer = changes.runningODO;
        item.vehicleStatusValue = changes.singleStatus;

        if (item.lastPosition == undefined) { alert(1); }

        if (item.lastPosition.locationString == undefined) {
            item.lastPosition.locationString = {};
        };

        item.lastPosition.locationString.address = changes.address;
        item.lastPosition.bpStatusAttribute = changes.attributeTypeId;
        item.lastPosition.direction = changes.directionString;
        item.lastPosition.distance = changes.distance;
        item.lastPosition.lastKnownEventTime = changes.eventTime;
        item.lastPosition.heading = changes.heading;
        item.lastPosition.speed = changes.speed;
        item.lastPosition.runningOdometer = changes.runningODO;
        item.lastPosition.status = changes.singleStatus;
        item.lastPosition.statusText = changes.statusText;
        item.lastPosition.streetlimit = changes.address.streetlimit
        item.lastPosition.jobId = item.jobId ? item.jobId : "";
        item.lastPosition.jobStatus = item.jobStatus ? item.jobStatus : "";

        if (liveMapLayers) {
            liveMapLayers.updateAsset(item);
        }
    },
    setLazyLoadingState(state, percentage) {
        state.lazyLoading = percentage > 98 ? 0 : percentage;
    },
    clearStore(state) {
        state.vehicles.splice(0);
        state.drivers.splice(0);
        state.locations.splice(0);
        //Object.assign(state.vehicles, []);
        //Object.assign(state.drivers, []);
        //Object.assign(state.locations, []);
        console.log("Store State", ...state.vehicles, ...state.drivers, ...state.locations);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
