<template>
    <div>
        <div v-if="showUpdateNow" @click="updateNow" class="UpdateNowLabel">
            <md-icon>refresh</md-icon>
            <div>{{ $t("click to update") }}</div>
        </div>
        <div>
            <v-row style="padding: 25px 29px 0 29px; text-align: center;" dense>
                <v-col cols="12">
                    <!--<input placeholder="Filter"
        v-model="searchFilter"
        aria-label="Filter"
        id="input_6"
        aria-invalid="false"
        class="inputDesign" />-->
                    <v-text-field v-model="searchFilter"
                                  placeholder="Filter"
                                  id="input_6"
                                  solo flat outlined dense hide-details>
                        <template v-slot:append>
                            <v-menu v-model="filterMenu" offset-y min-width="380" max-width="380" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon>mdi-filter</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-btn text outlined block @click="getDefaultSearch">All/None</v-btn>
                                    </v-list-item>
                                    <v-row no-gutters>
                                        <v-col v-for="(item, index) in alertTypeFilters"
                                               :key="index"
                                               cols="12"
                                               sm="6">
                                            <v-list-item>
                                                <v-list-item-icon>
                                                    <kendo-switch v-model="item.search" @change="refreshAlerts"></kendo-switch>
                                                </v-list-item-icon>
                                                <v-list-item-content class="filterMenuItems">
                                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-btn @click="updateAlertStatus(true)"
                           text
                           class="v-buttons">
                        <v-icon>cancel</v-icon>
                        <!--{{ $t("CustomeViews.dismissAllonPage") }}-->
                        Dismiss Page
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn @click="dismissAllAlerts"
                           text
                           class="v-buttons">
                        <v-icon>cancel</v-icon>
                        {{ $t("CustomeViews.dismissAll") }}
                    </v-btn>
                </v-col>
            </v-row>
            <div class="overflow" v-show="showList">
                <div class="alertLabel">
                    <div>{{ $t("FleetLabels.fleetAlerts") }}</div>
                    <v-btn plain text small v-show="hasFilter" @click="filterMenu = true" class="alertLabel" style="font-size: small; justify-content: flex-end; padding-right: 0; ">
                        <v-icon>mdi-filter</v-icon>
                        &nbsp;({{$t("filterState.FILTERED")}})
                    </v-btn>
                </div>
                <v-row class="dateWrapper" v-if="count > 0">
                    <v-col cols="4">
                        <div v-if="currentPage > 1" @click="--currentPage;checkAlertStatus()">
                            <span>
                                <md-icon>keyboard_arrow_left</md-icon>
                                {{$t("VehicleAlertActivity.previous")}}
                            </span>
                        </div>
                    </v-col>
                    <v-col cols="5">
                        <div> Page {{currentPage}} of {{totalPages}} </div>
                    </v-col>
                    <v-col cols="3">
                        <div v-if="currentPage < totalPages" @click="++currentPage;checkAlertStatus()">
                            <span>
                                {{$t("VehicleAlertActivity.next")}}
                                <md-icon>keyboard_arrow_right</md-icon>
                            </span>
                        </div>
                    </v-col>
                </v-row>

                <div>
                    <md-list class="md-double-line">
                        <!-- <RecycleScroller
      class="scroller"
      :items="filteredList"
      :item-size="60"
      key-field="id"
      style="height: 1300px; top: 0px"
      data-config='{"delay" : 300 , "amount" : 30}'
      id="vehiclescrollableList"
      ref="infoBusiness"
    > -->
                        <!-- <template v-slot:default="{ item }"> -->
                        <md-list-item :key="item.id" v-for="item in filteredList" @click="showInfo(item)">
                            <div class="md-list-item-text">
                                <span>
                                    <strong>{{ item.alertType }}</strong>
                                </span>
                                <span>
                                    {{ item.alertGenerateDateTime | $formatUTCDateTime }}
                                </span>
                            </div>
                            <md-icon>keyboard_arrow_right</md-icon>
                            <md-divider md-inset></md-divider>
                        </md-list-item>

                        <!--<v-list>
                            <v-list-item :key="item.id" v-for="item in filteredList">
                                <div class="md-list-item-text">
                                    <span>
                                        <strong>{{ item.alertType }}</strong>
                                    </span>
                                    <span>
                                        {{item.alertGenerateDateTime | $formatUTCDateTime}}
                                    </span>
                                </div>
                                <md-button @click="showInfo(item)"
                                           class="md-icon-button md-list-action">
                                    <md-icon>keyboard_arrow_right</md-icon>
                                </md-button>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                        <v-pagination v-model="currentPage"
                                      :length="totalPages">
                        </v-pagination>-->
                        <!-- </template>
    </RecycleScroller> -->
                        <md-divider md-inset></md-divider>
                        <v-row class="dateWrapper" v-if="count > 0">
                            <v-col cols="4">
                                <div v-if="currentPage > 1" @click="--currentPage;checkAlertStatus()">
                                    <span>
                                        <md-icon>keyboard_arrow_left</md-icon>
                                        {{$t("VehicleAlertActivity.previous")}}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="5">
                                <div> Page {{currentPage}} of {{totalPages}} </div>
                            </v-col>
                            <v-col cols="3">
                                <div v-if="currentPage < totalPages" @click="++currentPage;checkAlertStatus()">
                                    <span>
                                        {{$t("VehicleAlertActivity.next")}}
                                        <md-icon>keyboard_arrow_right</md-icon>
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </md-list>
                </div>
            </div>

        </div>
        <div class="notificationCol" v-if="noShowFlag">
            <div>{{ $t("CustomeViews.noAlertsToShow") }}</div>
            
        </div>
        <div class="drawerbackground" v-if="!showList && count > 0">
            <div class="backButton">
                <v-btn text block @click=" showList = !showList; selectedAlert = null;">
                    <v-icon>mdi-arrow-left</v-icon> {{ $t("FleetLabels.back_to_alerts") }}
                </v-btn>
            </div>
            <div class="alertLabel" style="color: black">
                <div>{{ selectedAlert.alertType }}</div>
            </div>
            <div class="alertLabel" style="color: black">
                <div>
                    {{ selectedAlert.rulePolicyName ? `${selectedAlert.rulePolicyName} - ` : ""}}
                    {{ selectedAlert.details }}
                </div>
            </div>
            <div @click="updateAlertStatus()">
                <md-button class="md-raised md-primary">Dismiss alert</md-button>
            </div>
        </div>
    </div>
</template>

<script>
    import RuleService from "../../../service/rule.service";
    //import { EventBus } from "./../../../shared/eventbus.js";
    //import Vue from "vue";
    //Vue.prototype.$eventBus = EventBus;
    import Constant from "./../../../../constant";
    import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
    import { debug } from "util";

    export default {
        name: "AlertNotification",
        components: {
            HubConnection,
            HubConnectionBuilder,
        },
        async created() {
            await RuleService.getNotActionCount().then(response => {
                this.totalCount = response
            })
            this.$eventBus.$on('checkAlertStatus', this.checkAlertStatus);
            this.checkAlertStatus();
            //this.signalRrevehicle();
        },
        mounted() {
            this.$eventBus.$on("DismissAll", this.dismissAll);
        },
        data() {
            return {
                filterMenu:false,
                filterUser: "",
                dataList: [],
                count: 0,
                showList: false,
                selectedAlert: null,
                searchFilter: "",
                noShowFlag: false,
                noActionHit: 0,
                dismissAll: "",
                currentPage: 1,
                pageSize: 50,
                totalCount: 0,
                filteredCount: 0,
                totalPages: 1,
                showUpdateNow: false,
                nextPageToken: [{ pageNum: 1, pageToken: null }],
                AllNone: false,
                alertTypeFilters: [
                    { text: this.$tc("FleetLabels.Activity"), value: "Activity", search: true },
                    { text: this.$tc("FleetLabels.DeviceTampering"), value: "DeviceTampering", search: true },
                    { text: this.$tc("FleetLabels.HarshDriving"), value: "HarshDriving", search: true },
                    { text: this.$tc("FleetLabels.Idling"), value: "Idling", search: true },
                    { text: this.$tc("FleetLabels.LongStop"), value: "LongStop", search: true },
                    { text: this.$tc("FleetLabels.Ignition"), value: "Ignition", search: true },
                    { text: this.$tc("FleetLabels.Inactivity"), value: "Inactivity", search: true },
                    { text: this.$tc("FleetLabels.LateStart"), value: "LateStart", search: true },
                    { text: this.$tc("FleetLabels.EarlyArrival"), value: "EarlyArrival", search: true },
                    { text: this.$tc("FleetLabels.NotCheckedIn"), value: "NotCheckedIn", search: true },
                    { text: this.$tc("FleetLabels.Speeding"), value: "Speeding", search: true },
                    { text: this.$tc("FleetLabels.RouteDeviation"), value: "RouteDeviation", search: true },
                //    { text: this.$tc("FleetLabels.location"), value: "Geofence", search: true },
                ],
            };
        },
        computed: {
            filteredList() {
                return this.dataList;
            },
            hasFilter() {
                return this.alertTypeFilters.filter(i => i.search == false).length > 0 ? true : false;
            },
        },
        methods: {
            getDefaultSearch() {
                if (this.alertTypeFilters.some(item => !item.search)) {
                    this.AllNone = true
                }
                this.alertTypeFilters.forEach(element => {
                    element.search = this.AllNone;
                });
                this.AllNone = !this.AllNone
                this.refreshAlerts()
            },
            updateNow() {
                this.currentPage = 1;
                this.checkAlertStatus();
            },
            showInfo(item) {
                this.showList = false;
                this.selectedAlert = item;
            },
            dismissAll(messageWindow) {
                this.dismissAll = messageWindow;
                this.updateAlertStatus(this.dismissAll);
            },
            dismissAllAlerts() {
                let alertType = "null"
                if (this.alertTypeFilters.filter(function (alertTypeFilter) { return alertTypeFilter.search }).length > 0) {
                    alertType = this.alertTypeFilters.filter(function (alertTypeFilter) { return alertTypeFilter.search })
                        .map(function (alertTypeFilter) { return alertTypeFilter.value })
                        .join()
                }
                let overrideParams = {
                    details: `**`,
                    alertType: alertType,
                    sortCriteria: "-alertGenerateDateTime",
                };

                RuleService.getAlertNotification(overrideParams)
                    .then((response) => {
                        let body = [];
                        body = response.data.map((item) => ({
                            id: item.id,
                            actionComment: "dismissed",
                        }));
                        if (body.length > 0) {
                            RuleService.updateAlertAction(body).then((response) => {
                                this.$eventBus.$emit("notificationUpdate", true);

                                this.dataList = [];
                                this.count = 0;
                                this.$emit("count", this.count);
                                this.noShowFlag = true;
                                this.showList = !this.showList;
                                this.selectedAlert = null;
                                this.updateNow();
                            })
                            RuleService.getNotActionCount().then(response => {
                                this.$emit("count", response);
                                this.totalCount = response
                            }).catch((err) => {
                                this.$emit("count", 0);
                                console.log(err);
                            });
                        }
                    })
                    .catch((err) => {
                        if (this.noActionHit < 3) {
                            setTimeout(() => {
                                ++this.noActionHit;
                                this.checkAlertStatus();
                            }, 1000);
                            console.log(err);
                        }
                    });
            },
            updateAlertStatus(dismissAll = false) {
                let body = [];
                if (dismissAll == true) {

                    // if (this.searchFilter) {
                    //   body = this.filteredList.map((item) => ({
                    //     id: item.id,
                    //     actionComment: "dismissed",
                    //   }));
                    // } else {
                    body = this.dataList.map((item) => ({
                        id: item.id,
                        actionComment: "dismissed",
                    }));
                    // }
                } else {
                    body.push({
                        id: this.selectedAlert.id,
                        actionComment: "dismissed",
                    });
                }
                if (body.length > 0) {
                    RuleService.updateAlertAction(body).then((response) => {
                        this.$eventBus.$emit("notificationUpdate", true);
                        if (dismissAll == true) {
                            if (this.searchFilter) {
                                this.count = this.dataList.length - this.filteredList.length;

                                this.filteredList.filter((selectedAlert) => {
                                    const deletedIndex = this.dataList.findIndex(
                                        (item) => item.id == selectedAlert.id
                                    );
                                    this.dataList.splice(deletedIndex, 1);
                                    return selectedAlert;
                                });

                                this.searchFilter = "";
                                /*this.$emit("count", this.count);*/
                                if (this.dataList.length == 0) {
                                    this.noShowFlag = true;
                                    this.showList = !this.showList;
                                    this.selectedAlert = null;
                                }
                                this.updateNow();
                            } else {
                                this.dataList = [];
                                this.count = 0;
                                this.$emit("count", this.count);
                                this.noShowFlag = true;
                                this.showList = !this.showList;
                                this.selectedAlert = null;
                                this.updateNow();
                            }

                        } else {
                            const deletedIndex = this.dataList.findIndex(
                                (item) => item.id == this.selectedAlert.id
                            );
                            this.dataList.splice(deletedIndex, 1);

                            this.count = this.count - 1;
                            this.showList = !this.showList;
                            if (this.dataList.length == 0) {
                                this.noShowFlag = true;
                                this.selectedAlert = null;
                            }
                        }
                        RuleService.getNotActionCount().then(response => {
                            this.$emit("count", response);
                            this.totalCount = response
                        }).catch((err) => {
                            this.$emit("count", 0);
                            console.log(err);
                        });
                    
                    });
                }
            },
            async checkAlertStatus() {
                let alertType = "null"
                if (this.alertTypeFilters.filter(function (alertTypeFilter) { return alertTypeFilter.search }).length > 0) {
                    alertType = this.alertTypeFilters.filter(function (alertTypeFilter) { return alertTypeFilter.search })
                        .map(function (alertTypeFilter) { return alertTypeFilter.value })
                        .join()
                }
                let overrideParams = {
                    pageSize: this.pageSize,
                    page: this.currentPage,
                    details: `*${this.searchFilter}*`,
                    alertType: alertType,
                    sortCriteria: "-alertGenerateDateTime",
                    nextPageToken: this.nextPageToken[this.currentPage - 1].pageToken
                };
                if (this.searchFilter || this.alertTypeFilters.some(item => !item.search)) {
                    RuleService.getNotActionCount({ details: `*${this.searchFilter}*`, alertType: alertType }).then(response => {
                        this.filteredCount = response
                    })
                } else {
                    this.filteredCount = this.totalCount
                }
                await RuleService.getAlertNotification(overrideParams)
                    .then((response) => {
                        this.showUpdateNow = false;
                        this.calculateNotifications(response.data, response.count);
                        if (this.totalPages != response.page && this.nextPageToken[response.page] == undefined) {
                            this.nextPageToken.push({ pageNum: response.page + 1, pageToken: response.nextPageToken })
                        }
                    })
                    .catch((err) => {
                        if (this.noActionHit < 3) {
                            setTimeout(() => {
                                ++this.noActionHit;
                                this.checkAlertStatus();
                            }, 1000);
                            console.log(err);
                        }
                    });
            },
            calculateNotifications(data, count) {
                this.dataList = data;
                if (this.dataList.length == 0) {
                    this.showList = false;
                    this.noShowFlag = true;
                } else {
                    if (!this.count) {
                        this.showList = true;
                        this.noShowFlag = false;
                    }
                }
                this.totalPages = Math.ceil(this.filteredCount / this.pageSize)
                this.count = count;
                // this.count = this.dataList.length;
                //this.$emit("count", this.count);
            },
            refreshAlerts() {
                this.$nextTick(() => {
                    this.currentPage = 1
                    this.nextPageToken = [{ pageNum: 1, pageToken: null }]
                    this.checkAlertStatus()
                });
                
            }
        },
        watch: {
            searchFilter: _.debounce(function () {
                this.refreshAlerts()
            }, 800),
        },
        beforeDestroy () {
            this.$eventBus.$off('checkAlertStatus', this.checkAlertStatus);
            this.$eventBus.$off("DismissAll", this.dismissAll);
        },
    };
</script>
<style scoped>
    .overflow {
        overflow-y: auto;
        height: calc(100vh - 237px);
    }

    .scroller {
        height: 100%;
    }

    .alertLabel {
        flex: 1;
        font-size: 18px;
        color: #288dc1;
        font-weight: normal;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: rgb(212, 212, 212);
    }

    .md-list-item {
        background: rgb(212, 212, 212);
        border-bottom: 1px solid black;
    }

    .notificationRow {
        display: flex;
        justify-content: center;
        padding: 10px 20px;
    }

    .notificationCol {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
    }

    .drawerbackground {
        background: rgb(212, 212, 212);
        height: 100%;
    }

    .backButton {
        background: white !important;
        text-align: center;
    }

    .dateWrapper {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0;
        padding: 0;
        border: 0;
        width: 100%;
        color: #288dc1;
    }

        .dateWrapper .md-icon.md-theme-default.md-icon-font {
            color: #288dc1;
        }

    .date-changer {
        font: inherit;
        width: 35%;
        vertical-align: baseline;
        color: #288dc1;
        margin: 10px 15px;
        text-align: center;
    }

    .date-changer-arrow {
        width: auto;
        bottom: 2px;
        position: relative;
        cursor: pointer;
        margin: 10px 15px;
    }

    .UpdateNowLabel {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        line-height: 1.25em;
        top: 2px;
        position: relative;
        cursor: pointer;
    }
</style>
