import Vue from 'vue';
import { defineStore } from 'pinia'
import driverService from "@/service/drivers.service";
export const useDriverStore = defineStore('drivers', {
    state: () => ({
        operators: [],
        operatorsLight : [],
        tenantId: "",
    }),
    //$driverStore - Sorted by displayName
    actions: {

        /** Reads driver list from backend service and overwrites the current state
         * @param {Array<String>} ids
         */
        async getOperators(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await driverService.getManageDriversByIds(ids).then(operatorData => {
                this.operators = operatorData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Drivers');
                this.operators = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

         async getOperatorsSelectionData(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await driverService.getDriverLight(ids).then(operatorData => {
                this.operatorsLight = operatorData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Drivers');
                this.operatorsLight = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },
         getOperatorsSelectorData() {
            return this.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
                ? this.operators.length > 0 
                    ? this.operators 
                    : this.operatorsLight
                : [];
        },
        /** Clears the current store       
         */
        async clearOperatorsStore() {
            this.operators = [];
            this.tenantId = "";
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array of Operators
         */
        getOperatorsData() {
           let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.operators
                : []
        },

        /** Removes operators based on provided ids         
         * @param {[String]} ids
         */
        removeOperatorsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            this.operators = [
                ...this.operators
                    .filter(i => !ids.includes(i.id))
                    .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
            ];
        },

        /** Reads Operator records based on ids provided and adds them to the store
         * @param {[String]} ids
         */
        async addOperatorsByIds(ids) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            let self = this;
            await driverService.getManageDriversByIds(ids).then(operatorData => {
                let stripIds = [...(Array.isArray(ids) ? ids : []), ...operatorData.map(i => i.id)];
                self.operators = [...new Set([
                    ...self.operators.filter(i => !stripIds.includes(i.id)),
                    ...operatorData
                ])].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Drivers');
                this.operators = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Upserts store states based on provided object array
         * @param {[operatorEntry]} operatorList
         */
        async addOperatorsByObject(operatorList) {
            if (!Array.isArray(operatorList)) { operatorList = [operatorList] };
            let targetIds = operatorList.map(i => i.id);
            this.operators = [
                ...this.operators.filter(i => !targetIds.includes(i.id)),
                ...operatorList
            ].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
        },

        /** Replaces state with provided array
         * @param {[OperatorEntry]} assetsData
         */
        replaceOperatorsState(operatorData) {
            if (!Array.isArray(operatorData)) { operatorData = [operatorData] };
            this.operators = operatorData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
        },

    },
})