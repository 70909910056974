import { useGroupsFilterStore } from '@/stores/groups-store';
import axios from 'axios';

class contactService {

    getManageContact(id) {
        return axios.get(`/api/contact/GetManageContact/${id}`)
    }

    createContact(body, id) {
        if (id) return axios.put(`/api/contact/EditContact/${id}`, body)
        return axios.post(`/api/contact/CreateContact`, body)
    }

    getContactByIds(ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.contacts.length > 0) {
            return axios.post(`/api/contact/GetContactByIds`, ids ? ids : groupFilterStore.contacts)
        } else {
            return Promise.resolve([]);
        };
    }
    getAllContactsListed() {
        return axios.get(`/api/contact/GetAllContactsListed`)
    }

    deleteContactby(body) {
        return axios.post(`/api/contact/DeleteContactby`, body)
    }
}

export default new contactService();