import Vue from 'vue';

class CommonUtil {

    convertDistance(value) {
        return `${this.getConvertedDistance(value)} ${this.getDistanceUnit()}`;
    }
     convertDistanceNoSuffix(value) {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return value;
        }
        const unit = configProperties.distanceMesurementUnit;
        if(unit == 'km'){
            if(value < 1000){
               return this.getConvertedDistance(value*1000) + " m";
            }
            return `${this.getConvertDistanceDecimal(value)} ${this.getDistanceUnit()}`;
        }else{
            if(value < 1000){
               return this.getConvertedDistance(value * 3.28084 * 1609.344) + " ft";
            }
            return `${this.getConvertDistanceDecimal(value)} ${this.getDistanceUnit()}`; 
        }
    }
    convertDistanceDecimal(value) {
        return `${this.getConvertDistanceDecimal(value)} ${this.getDistanceUnit()}`;
    }

    convertDistanceDecimalNoSuffix(value) {
        return `${this.getConvertDistanceDecimal(value)}`;
    }
    getDistanceUnit() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.distanceMesurementUnit;
    }

    getConvertedDistance(value) {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return value;
        }
        const unit = configProperties.distanceMesurementUnit;
        let calculatedValue = unit == 'km' ? (value / 1000) : (value / 1609.344);
        calculatedValue = Math.round(calculatedValue);
        if(calculatedValue < 0)
            calculatedValue = 0
        return calculatedValue;
    }
    getConvertDistanceDecimal(value) {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
        return value;
        }
        const unit = configProperties.distanceMesurementUnit;
        let calculatedValue = unit == 'km' ? (value / 1000) : (value / 1609.344);
        calculatedValue = Math.round(calculatedValue * 100) / 100;
        if(calculatedValue < 0)
            calculatedValue = 0
        return calculatedValue;
    }

    convertSpeed(value) {
        return `${this.getConvertedSpeed(value)}`;
    }

    getSpeedUnit() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.speedMesurementUnit;
    }

    getConvertedSpeed(value) {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return value;
        }
        const unit = configProperties.speedMesurementUnit;
        let calculatedValue = unit == 'km/h' ? value : (value / 1.609);
        calculatedValue =  calculatedValue?Math.round(calculatedValue):0;
        if(calculatedValue < 0)
            calculatedValue = 0
        return calculatedValue;
    }

    convertFuel(value) {
        return `${this.getConvertedFuel(value)} ${this.getFuelUnit()}`;
    }

    getFuelUnit() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.fuelMeasurementUnit;
    }

    getConvertedFuel(value) {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return value;
        }
        const unit = configProperties.speedMesurementUnit;
        let calculatedValue = unit == 'gallon' ? (value / 3785.412) : (value / 1000);
        calculatedValue = Math.round(calculatedValue);
        return calculatedValue;
    }

    getCurrencyUnit() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.currency;
    }

    getDisplayVehicleFlagStatus() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.displayVehicleFlagStatus;
    }

    getStatusFlagDisplay() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.statusFlagDisplay;
    }

    getStatusFlagDetails() {
        const configProperties = Vue.prototype.$session.get("configProperties");
        if (!configProperties) {
            return '';
        }
        return configProperties.statusFlagDetails;
    }

    driverInfoFormat(driver, showFullString = false) {
        let value = "";
        if (driver && driver!=undefined) {
            const configProperties = Vue.prototype.$session.get("configProperties");
            if (!configProperties) {
                return '';
            }
            const format = configProperties.driverInfoFormat;
            const firstName = driver.givenName;
            const lastName = driver.surname;
            if (firstName && lastName) {
                if (format == 'FirstName LastName') {
                    value = `${firstName} ${lastName}`;
                }
                else if (format == 'LastName FirstName') {
                    value = `${lastName} ${firstName}`;
                }
            }
            else {
                value = driver.displayName;
            }
        }
        /* for search list & where we want to show full text of given item */
        if (showFullString == false){
            value = value && value.length >= 15 ? `${value.substring(0, 15)}...` : value;
        }
        return value;
    }

    vehicleInfoFormat(vehicle, showFullString = false) {
        let value = "";
        let display = "";
        if (vehicle) {
            if(vehicle.defaultVehicleId){
                display = vehicle.defaultVehicleId;
            }
            if(!display){
                const configProperties = Vue.prototype.$session.get("configProperties");
                display = configProperties.defaultVehicleId;
            }
            if (!display) {
                return '';
            }
            display = String(display).toLowerCase();
            if (display == 'vehicle registration number' || display == 'registration number') {
                value = vehicle.metadata ? vehicle.metadata.registrationNumber : (vehicle.registrationNumber?vehicle.registrationNumber:"");
            }
            else if (display == 'fleet number') {
                value = vehicle.metadata ? vehicle.metadata.fleetNumber : (vehicle.fleetNumber ? vehicle.fleetNumber:"");
            }
            else if (display == 'vehicle name') {
                value = vehicle.name || vehicle.vehicleName || "";
            }
        }

        if(value == '') {
            value = vehicle ? (vehicle.metadata ? vehicle.metadata.registrationNumber : (vehicle.registrationNumber?vehicle.registrationNumber:"")) : "";
        }
        /* for search list & where we want to show full text of given item */
        if (showFullString == false){
            value = value && value.length >= 15 ? `${value.substring(0, 15)}...` : value;
        }

        return value;
    }

    vehicleInfoFormatGroup(vehicle) {
        let value = "";
        let display = "";
        if (vehicle) {
            if(vehicle.defaultVehicleId){
                display = vehicle.defaultVehicleId;
            }
            if(!display){
                const configProperties = Vue.prototype.$session.get("configProperties");
                display = configProperties.defaultVehicleId;
            }
            if (!display) {
                return '';
            }
            display = String(display).toLowerCase();
            if (display == 'vehicle registration number' || display == 'registration number') {
                value = vehicle.metadata ? vehicle.metadata.registrationNumber : (vehicle.registrationNumber?vehicle.registrationNumber:"");
            }
            else if (display == 'fleet number') {
                value = vehicle.metadata ? vehicle.metadata.fleetNumber : (vehicle.fleetNumber ? vehicle.fleetNumber:"");
            }
            else if (display == 'vehicle name') {
                value = vehicle.name ? vehicle.name : (vehicle.vehicleName ? vehicle.vehicleName:"");
            }
        }

        if(value == '') {
            value = vehicle.metadata ? vehicle.metadata.registrationNumber : (vehicle.registrationNumber?vehicle.registrationNumber:"");
        }
        
        value = value && value.length >= 15 ? `${value.substring(0, 15)}...` : value;
        return value;
    }

    driverIdFormat(driver) {
        let value = "";
        if (driver && driver!=undefined) {
            const configProperties = Vue.prototype.$session.get("configProperties");
            if (!configProperties) {
                return '';
            }
            const format = configProperties.driverDisplayName;

            if (format == 'Driver ID') {
                value = `${driver.driverId}`;
            }
            else if (format == 'Driver Name') {
                value = this.driverInfoFormat(driver);
            }
        }
        // value = value && value.length >= 20 ? `${value.substring(0, 20)}...` : value;
        return value;
    }

    //getDefaultDriverWH() {
    //    const configProperties = Vue.prototype.$session.get("configProperties");
    //    if (!configProperties) {
    //        return {};
    //    }
    //    return Array.isArray(configProperties.statusFlagDetails) ? configProperties.defaultDriverWorkingHour[0] : {};
    //}
    
    _calcSlope(x1, y1, x2, y2, l) {
      let arct = Math.atan2((y1 - y2), (x1 - x2));
      let m = {
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2
      }
      return {
        start: m,
        end: {
          x: (-Math.sin(arct) * l + m.x),
          y: (Math.cos(arct) * l + m.y)
        }
      };
    }
    _pointerLength(topPoint, bottomPoint) {
      let x = Math.abs(bottomPoint.x - topPoint.x);
      let y = Math.abs(bottomPoint.y - topPoint.y);
      return ((x > y) ? x : y) / 2;
    }
}
export default new CommonUtil();