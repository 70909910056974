import axios from 'axios';
import _ from 'lodash';
import filters from '@/shared/filters';

class ReplayService {

    getAll(payload) {
        return axios.post('/api/replay', payload).then(response => {
            _.each(response.trips.vehicles, item => {
                if (item.details) {
                    item.details.profileImage = filters.methods.$getBlobStorageImage(item.details.profileImage);
                    item.details.vehicleIcon = filters.methods.$getBlobStorageImage(item.details.vehicleIcon);
                    if (item.details.assignedDriver) {
                        item.details.assignedDriver.driverProfileImage = filters.methods.$getBlobStorageImage(item.details.assignedDriver.driverProfileImage);
                    }
                }
            });
            return response;
        });
    }

    /* { startDate: date, endDate: date, vehicles: string[], drivers: string[] } */
    getTrips(payload) {
        return axios.post('/api/replay/trips', payload).then(response => {
            _.each(response.vehicles, item => {
                if (item.details) {
                    item.details.profileImage = filters.methods.$getBlobStorageImage(item.details.profileImage);
                    item.details.vehicleIcon = filters.methods.$getBlobStorageImage(item.details.vehicleIcon);
                    if (item.details.assignedDriver) {
                        item.details.assignedDriver.driverProfileImage = filters.methods.$getBlobStorageImage(item.details.assignedDriver.driverProfileImage);
                    }
                }
            });
            return response;
        });
    }

    /* { startDate: date, endDate: date, items: [id: string, trips: string[]] } */
    getTripDetails(payload) {
        return axios.post('/api/replay/trip-details', payload);
    }
}

export default new ReplayService();