import axios from 'axios';

class ruleService{

    getAllRulePolicy(params, id) {
        if (id) {
            return axios.get(`/api/rule/GetRulePolicy/${id}`, { params: params });
        } 
        return axios.get(`/api/rule/GetAllRulePolicy`, { params: params });
    }
    getRulePoliciesAssignments(params) {
        return axios.get(`/api/rule/GetRulePoliciesAssignments`, { params: params });
    }

    getRuleInfo(startTIme, endTIme) {
        const params = {
            'startTIme': startTIme,
            'endTIme': endTIme,
        }
        return axios.get(`/api/rule/GetRuleInfo`, { params: params });
    }

    gettripFleetAlerts(starttime, endtime, vehicleId, driverId, alertType) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            vehicleId: vehicleId,
            driverId: driverId,
            alertType: alertType
        }
        return axios.get(`/api/rule/GetTripFleetAlerts`, { params: params });
    }

    getActionedAlertSummary(starttime, endtime, actionValue) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            actioned: actionValue
        }
        return axios.get(`/api/rule/GetActionedAlertSummary`, { params: params });
    }

    getAlertSummaryByCategory(starttime, endtime, alertType) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            alertType: alertType
        }
        return axios.get(`/api/rule/GetAlertSummaryByCategory`, { params: params });
    }

    getAlertNotification(params = {}) {
        return axios.get(`/api/rule/GetAlertNotification`, { params: params });
    }

    getNotActionCount(params = {}) {
        return axios.get(`/api/rule/GetNotActionCount`, { params: params });
    }

    deleteById(id) {
        return axios.delete(`/api/rule/DeleteById/${id}`);
    }

    multipleDeltePolicy(body) {
        return axios.delete(`/api/rule/MultipleDeltePolicy`, { data: body });
    }

    editPolicy(body, id) {
        return axios.put(`/api/rule/EditPolicy/${id}`, body);
    }

    updateAlertAction(body) {
        return axios.put(`/api/rule/UpdateAlertAction`, body);
    }

	createNewAlertPolicy(body) {
         return axios.post(`/api/rule/CreateNewAlertPolicy`, body);
    }
    
    clonePolicy(body){
        return axios.post(`/api/rule/ClonePolicy`, body);
    }

    getPolicyByVehicles(body) {
        return axios.post(`/api/rule/GetPolicyByVehicles`,  body );
    }

    getPolicyByUserIds(body) {
        return axios.post(`/api/rule/GetPolicyByUserIds`,  body );
    }
}
export default new ruleService();