import { useGroupsFilterStore } from '@/stores/groups-store';
import axios from 'axios';

class DriverService {

    getManageDriver(id) {
        return axios.get(`/api/driver/GetManageDriver/${id}`);
    }

    getAssignable(driverId) {
        if (driverId) {
            return axios.get(`/api/driver/${driverId}/assignable`);
        }
        return axios.get(`/api/driver/undefined/assignable`);
    }
    
    getDriverTags() {
        return axios.get(`/api/driver/GetDriverTags`);
    }

    validateDriverId(id) {
        return axios.get(`/api/driver/ValidateDriverId/${id}`);
    }

    validateDriverEmail(email) {
        return axios.get(`/api/driver/ValidateDriverEmail/${email}`);
    }

    getAllDriverSkillset() {
        return axios.get(`/api/driver/GetAllDriverSkillset`);
    }

    getManageDriversByIds(ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.drivers.length > 0) {
            return axios.post('/api/driver', ids ? ids : groupFilterStore.drivers);
        } else {
            return Promise.resolve([]);
        };
        
    }

    getDriverLight(ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.drivers.length > 0) {
            return axios.post('/api/driver/LiveMapGetAll', ids ? ids : groupFilterStore.drivers);
        } else {
            return Promise.resolve([]);
        };
    }

    getAllDriversListed() {
        return axios.get('/api/driver/GetAllDriversListed');
    }

    createDriver(body, id) {
        if (id) return axios.put(`/api/driver/EditDriver/${id}`, body);
        return axios.post('/api/driver/CreateDriver', body);
    }

    getAllAllowedVehicle(body) {
        return axios.post('/api/driver/GetAllAllowedVehicle', body);
    }

    getAllWorkingHour(body) {
        params = { id: body.id }
        return axios.post(`/api/configuration/GetAllWorkingHours`, body, {params : params})
    }

    getAssignments(driverId) {
        return axios.get(`/api/driver/${driverId}/assignments`);
    }

    updateAssignment(driverId, model) {
        return axios.post(`/api/driver/${driverId}/assignments`, model);
    }

    updateAssignments(model) {
        return axios.post(`/api/driver/assignments`, model);
    }
    getAllWorkingHoursForDrivers(model) {
        return axios.post(`/api/driver/getAllWorkingHoursForDrivers`, model);
    }
    checkTag(tag) {
        return axios.get(`/api/driver/checkTag/${tag}`);
    }
}

export default new DriverService();