import Vue from 'vue';
import { defineStore } from 'pinia';
import insightsService from "@/service/insights.service";
//this.$insights.trackEvent('', '')
export const useInsightsStore = defineStore('insights', {
    state: () => ({
        insightsEventBase: {},
    }),
    actions: {
        async getInsights(subgroup) {
            let tenantDetails = Vue.prototype.$session.get("tenantDetails");
            await insightsService.getApplicationInsightsData(tenantDetails.userId, tenantDetails.tenantId)
                .then(response => {
                    var customEventProperties = {
                        firstName: response.firstName,
                        lastName: response.lastName,
                        email: response.email,
                        role: tenantDetails.role,
                        phoneNumber: response.phoneNumber,
                        accountNumber: response.accountNumber,
                        tenantName: response.tenantName,
                        subGroup: subgroup,
                        solutionName: response.solutionName
                    };
                    this.insightsEventBase = customEventProperties;
                    Vue.prototype.$appInsights.trackEvent({
                        name: "userDetails",
                        properties: customEventProperties
                    });
                });
        },

        //#region Page View Tracking
        trackPageView(pageName) {
            if (Vue.prototype.$appInsights !== undefined) {
                Vue.prototype.$appInsights.trackPageView({ name: pageName });
            };
        },
        startTrackPage(name) {
            if (Vue.prototype.$appInsights !== undefined) {
                setImmediate(() => {
                    Vue.prototype.$appInsights.startTrackPage('(Vue App) / ' + name);
                });
            };
        },
        stopTrackPage(name, url) {
            if (Vue.prototype.$appInsights !== undefined) {
                setImmediate(() => {
                    Vue.prototype.$appInsights.stopTrackPage(name, url);
                    Vue.prototype.$appInsights.flush();
                });
            };
        },
        //#endregion

        trackEvent(page, event, data = {}) {
            setImmediate(() => {
                let payload = {
                    name: "userInteraction",
                    properties: {
                        page: page,
                        event: event,
                        //...this.insightsEventBase,
                        //...data,
                    },
                };
                console.log("Track Event", payload.properties.page, payload.properties.event, payload);
                if (Vue.prototype.$appInsights !== undefined) {
                    Vue.prototype.$appInsights.trackEvent(payload);
                };
            });
        },
        trackError(page, event, data = {}) {
            setImmediate(() => {
                let payload = {
                    name: "errorEvent",
                    properties: {
                        page: page,
                        event: event,
                        //...this.insightsEventBase,
                        //...data,
                    },
                };
                console.log("Error Event", payload.properties.page, payload.properties.event, payload, data);
                if (Vue.prototype.$appInsights !== undefined) {
                    Vue.prototype.$appInsights.trackEvent(payload);
                };
            });
        },
    },
})