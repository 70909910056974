import { useGroupsFilterStore } from '@/stores/groups-store';
import axios from 'axios';

class VehicleService {

    getAllVehicles() {
        return axios.get("/api/device/non-communicable");
    }

    getAllAssetsListed() {
        return axios.get("/api/device/list");
    }

    getAllVehicleSolutionById(vehicleId) {
        return axios.get(`/api/vehicle/${vehicleId}`);
    }

    getAssignable(vehicleId) {
        if (vehicleId) {
            return axios.get(`/api/vehicle/${vehicleId}/assignable`);
        }
        return axios.get(`/api/vehicle/undefined/assignable`);
    }

    getAssignments(vehicleId) {
        return axios.get(`/api/vehicle/${vehicleId}/assignments`);
    }

    getAllVehicleChecklist(vehicleId) {
        return axios.get(`/api/vehicle/${vehicleId}/checklist`);
    }

    getAllVehicleSkillList() {
        return axios.get(`/api/vehicle/skilllist`);
    }

    getAllOdoRunningHours(vehicleId) {
        return axios.get(`/api/vehicle/${vehicleId}/logcorrection`);
    }

    deleteAllOdoRunningHours(vehicleId) {
        return axios.delete(`/api/vehicle/${vehicleId}/logcorrection`);
    }

    editVehicleData(body) {
        return axios.put(`/api/vehicle/${body.id}/edit`, body);
    }

    addAllOdoRunningHours(body, type) {
        if (type == 1) {
            return axios.post(`/api/vehicle/odo/runninghours/add`, body);
        } else {
            return axios.put(`/api/vehicle/odo/runninghours/edit`, body);
        }
    }

    getAllVehicleSolutionByIds(ids, forceReload = false) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.vehicles.length > 0) {
            if (forceReload == true) {
                return axios.post('/api/vehicle/all', ids ? ids : groupFilterStore.vehicles);
            } else {
                return axios.post('/api/vehicle', ids ? ids : groupFilterStore.vehicles);
            };
        } else {
            return Promise.resolve([]);
        };     
    }

    getAllVehiclesByIdsLight(ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.vehicles.length > 0) {
            return axios.post('/api/vehicle/ReplayGetAll', ids ? ids : groupFilterStore.vehicles);
        } else {
            return Promise.resolve([]);
        };
    }

    updateAssignment(vehicleId, model) {
        return axios.post(`/api/vehicle/${vehicleId}/assignments`, model);
    }

    getAssetLight(model) {
        return axios.post(`/api/vehicle/getAssetLight`, model);
    }

    updateAssignments(model) {
        return axios.post(`/api/vehicle/assignments`, model);
    }
    getAllWorkingHoursForAssets(model) {
        return axios.post(`/api/vehicle/getAllWorkingHoursForAssets`, model);
    }
}

export default new VehicleService();