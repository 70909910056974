import RBACService from "../../service/rbac.service";
import constant from "./../../../constant";
export default {
  data() {
    return {
      driverRole: {},
      tenantDetails: undefined,
      applicationId: undefined
    };
  },
    created() {
    this.getAllUserRole();
    this.tenantDetails = this.$session.get("tenantDetails");
    this.applicationId = this.$session.get("applicationId");
  },
  methods: {
    getAllUserRole() {
      let params = {
        applicationId: this.$session.get("applicationId")
      };
      this.driverRole = {};
      let driverSecurityRoleName = String(constant.driverSecurityRoleName).toLowerCase();
      RBACService.getSecurityRoles(params)
        .then(
          roles => {
            roles.forEach((roleItem, index) => {
              if (String(roleItem.SecurityRoleName).toLowerCase() === driverSecurityRoleName) {
                this.driverRole = {
                  roleId: roleItem.id,
                  roleName: roleItem.SecurityRoleName,
                  roleMembers: roleItem.RoleMembers
                };
              }
            });
          },
          error => {
            this.$toast.error(error.response.data.apiMessage);
          }
        )
        .catch(error => {
          this.$toast.error(this.$tc("Notification.roleAPIFailed"));
        });
    },
    /* remove driver role from Driver user on delete */
    removeDriverRoles(userId) {
      if (this.tenantDetails && this.applicationId && this.driverRole) {
        if (Array.isArray(this.driverRole.roleMembers) && this.driverRole.roleMembers.includes(userId)) {
          const formData = new FormData();
          formData.append("roleId", this.driverRole.roleId);
          formData.append("userId", userId);
          formData.append("applicationId", this.applicationId);
          RBACService.removeUserFromRole(formData)
          .then(
            (result) => {},
            (error) => this.$toast.error(this.$tc("Notification.roleUnAssigmentFailed"))
          ).catch((error) =>{});
        }
      }
    }
  }
};