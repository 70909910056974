import Vue from 'vue';
import { useGroupsFilterStore } from '@/stores/groups-store';
import axios from 'axios';

class LocationService {

    postMultipleVehicleLocationMapping(body) {
        return axios.post('/api/location/mappings/multiple/locationvehicle', body);
    }

    postMultipleVehicleLocationUnMapping(body) {
        return axios.post('/api/location/unmappings/multiple/locationvehicle', body);
    }

    getAllVehicleLocationMapping() {
        return axios.get('/api/location/mappings/all');
    }

    getAllVehicleLocationMappingByLocation(locationID) {
        return axios.get('/api/location/mappings/location/' + locationID);
    }

    getAllVehicleLocationMappingByVehiclePost(body) {
        return axios.post('/api/location/mappings/vehicleIds', body)
    }

    postLocation(body) {
        return axios.post('/api/location', body);
    }

    search() {
        return axios.get('/api/location/search')
    }
    getAllLocationsListed() {
        return axios.get('/api/location/getAllLocationsListed')
    }

    searchByGroupIds(ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.locations.length > 0) {
            return axios.post('/api/location/searchByGroupIds', ids ? ids : groupFilterStore.locations);
        } else {
            return Promise.resolve([]);
        };  
    }

    getById(id) {
        return axios.get('/api/location/' + id )
    }

    searchCategory() {
        return axios.get('/api/location/categories')
    }

    searchType() {
        return axios.get('/api/location/types')
    }

    addCategory(body) {
        return axios.post('/api/location/category', body);
    }

    addType(body) {
        return axios.post('/api/location/type', body);
    }

    deleteById(id) {
        return axios.delete('/api/location/tenant/' +
            Vue.prototype.$session.get("tenantDetails").tenantId +
            '/id/' + id);
    }

    getAddress(lat, lng) {
        return axios.get('/api/location/address/lat/' + lat + "/lng/" + lng)
    }

    getEdgeLocationEnums() {
        return axios.get('/api/location/edgeLocationTypes');
    }
}

export default new LocationService();